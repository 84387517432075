// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l5puSCi6B09yr0TlBtdP{display:flex;flex-flow:column nowrap;row-gap:10px}`, "",{"version":3,"sources":["webpack://./src/features/associate/views/InformationUpdateView/components/VehicleForms/component.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAEA,uBAAA,CACA,YAAA","sourcesContent":[".main {\n    display: flex;\n  \n    flex-flow: column nowrap;\n    row-gap: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `l5puSCi6B09yr0TlBtdP`
};
export default ___CSS_LOADER_EXPORT___;
