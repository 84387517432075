// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PbJsU2Ws4tpI6INV10Ue{display:grid;grid-column-start:1;grid-column-end:5;grid-auto-columns:1fr;grid-template-rows:min-content;grid-auto-flow:column;column-gap:10px}`, "",{"version":3,"sources":["webpack://./src/features/associate/views/InformationUpdateView/components/FormActions/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,mBAAA,CACA,iBAAA,CAEA,qBAAA,CACA,8BAAA,CACA,qBAAA,CAEA,eAAA","sourcesContent":[".main{\n  display: grid;\n\n  grid-column-start: 1;\n  grid-column-end: 5;\n\n  grid-auto-columns: 1fr;\n  grid-template-rows: min-content;\n  grid-auto-flow: column;\n\n  column-gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `PbJsU2Ws4tpI6INV10Ue`
};
export default ___CSS_LOADER_EXPORT___;
