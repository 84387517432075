// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fMRGNdSK9hEIG1VuDod_{display:flex;flex-flow:column nowrap;row-gap:5px;border:1px solid #e0e0e0;border-radius:8px;padding:10px}.fMRGNdSK9hEIG1VuDod_.m2OP3oXz2t6RoaKaePiP{width:100%}.koxZjWyxjk0uPLun4wvh{width:100%;font-size:14px}.cnaaQ8slT_IlvLHdoccl{display:grid;width:100%;grid-template-columns:1fr 20px;border-bottom:1px solid #e0e0e0}.AoZWh6uvLqHZm6mgd68V{width:100%;border:none;outline:none;font-family:"Montserrat",sans-serif;font-size:12px}.AoZWh6uvLqHZm6mgd68V:focus{outline:none;border:none}.F7XllfCX2rVTqVX5lElT{width:100%}span.RsBHWat17gOP9r_9psAg{width:100%;color:red;font-size:12px}`, "",{"version":3,"sources":["webpack://./src/common/components/Input/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,uBAAA,CACA,WAAA,CAEA,wBAAA,CACA,iBAAA,CACA,YAAA,CAEA,2CACE,UAAA,CAIJ,sBACE,UAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAEA,8BAAA,CACA,+BAAA,CAGF,sBACE,UAAA,CAEA,WAAA,CACA,YAAA,CAEA,mCAAA,CACA,cAAA,CAEA,4BACE,YAAA,CACA,WAAA,CAIJ,sBACE,UAAA,CAGF,0BACE,UAAA,CACA,SAAA,CACA,cAAA","sourcesContent":[".main {\n  display: flex;\n\n  flex-flow: column nowrap;\n  row-gap: 5px;\n\n  border: 1px solid #e0e0e0;\n  border-radius: 8px;\n  padding: 10px;\n\n  &.fullWidth {\n    width: 100%;\n  }\n}\n\n.label {\n  width: 100%;\n  font-size: 14px;\n}\n\n.inputWrapper {\n  display: grid;\n  width: 100%;\n\n  grid-template-columns: 1fr 20px;\n  border-bottom: 1px solid #e0e0e0;\n}\n\n.input {\n  width: 100%;\n\n  border: none;\n  outline: none;\n\n  font-family: 'Montserrat', sans-serif;\n  font-size: 12px;\n\n  &:focus{\n    outline: none;\n    border: none;\n  }\n}\n\n.icon {\n  width: 100%;\n}\n\nspan.error {\n  width: 100%;\n  color: red;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `fMRGNdSK9hEIG1VuDod_`,
	"fullWidth": `m2OP3oXz2t6RoaKaePiP`,
	"label": `koxZjWyxjk0uPLun4wvh`,
	"inputWrapper": `cnaaQ8slT_IlvLHdoccl`,
	"input": `AoZWh6uvLqHZm6mgd68V`,
	"icon": `F7XllfCX2rVTqVX5lElT`,
	"error": `RsBHWat17gOP9r_9psAg`
};
export default ___CSS_LOADER_EXPORT___;
